import React, { Fragment } from 'react';
import BlogHeader from '../../components/FeedHeader/BlogHeader';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import ProfileWidget from '../../components/ProfileWidget/ProfileWidget';
import BookmarkHeadlines from '../../components/Bookmark/BookmarkHeadlines';
import BookmarkLayout from '../../layouts/BookmarkLayout';
import { usePosts } from '../../hooks/usePosts';
import SpinnerMini from '../../components/Spinner/SpinnerMini';

const PostDetailsPage = () => {
  const bookmarkHeadlineCards = <BookmarkHeadlines />;

  const { isLoading } = usePosts();

  if (isLoading)
    return (
      <Fragment>
        <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
        <SpinnerMini />
      </Fragment>
    );

  return (
    <Fragment>
      <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
      <BookmarkLayout
        firstColumnContent={<ProfileWidget />}
        gridItems={bookmarkHeadlineCards}
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PostDetailsPage;
