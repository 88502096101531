import React, { useEffect } from 'react';
import { Box, Text } from '@deposits/ui-kit-react';

function Modal({ isOpen, onClose, text }) {
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isOpen && !event.target.closest('.modal__content')) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null;
    }

    return (
        <Box className="modal__layout">
            <Box className="modal__content">
                {text.map((item, index) => (
                    <Text className="modal__text" key={index}>
                        {item}
                    </Text>
                ))}
            </Box>
        </Box>
    );
}

export default Modal;
