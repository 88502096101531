import { useQuery } from '@tanstack/react-query';
import { getBusinessPosts } from '../../services/apiCategories';

export function useBusiness() {
  const {
    isLoading,
    data: posts,
    error,
  } = useQuery({
    queryKey: ['businessPosts'],
    queryFn: getBusinessPosts,
  });

  return { isLoading, posts, error };
}
