import React, { Fragment } from "react";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Subscribe from "../../components/Subscribe/Subscribe";
import Navbar from "../../components/Navbar/Navbar";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@material-ui/core";
import Background from "../../images/faq-bg.jpg"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getFaqData } from "../../services/utilsStringFuncs";
import Ticker from "../../components/TickerWidget/TickerReact";
import { usePosts } from "../../hooks/usePosts";
import SpinnerMini from "../../components/Spinner/SpinnerMini";

const loadPostsAndTicker = (posts) => {
    // log posts
    //console.log(posts);
    if (!posts) return <SpinnerMini />;
    var getRandomPosts = [...posts].sort(() => Math.random() - Math.random());
    var randomPosts = getRandomPosts.slice(0, 5);
    return <Box
      style={{ 
        alignContent: "center",
        margin: "0 auto",
        padding: "1rem",        
        width: "90%", 
        zIndex: "0",
        opacity: "1.0",
      }}
        >
        <Ticker posts={randomPosts} maxLength="150" />
      </Box>
};

const FAQ = () => {
    const { isLoading, posts } = usePosts();

    // show spinner while loading
    if (isLoading) return <SpinnerMini />;

  var faqData = getFaqData();

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-1"} topbarNone={"topbar-none"} />
      {loadPostsAndTicker(posts)}
      <Box
        className="faq-area"
        display={{ xs: "block", md: "block", lg: "flex" }}
        style={{    
            backgroundImage: `url(${Background})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        }}
        >
        
        <img src={Background} 
            alt="FAQ Background" 
            style={{     
                position: "absolute",            
                zIndex: "-1", 
                width: "100%", 
                height: "500px",
                objectFit: "fill",
                //dilute image 
                opacity: "0.2",           
            }}
        />
        
        <div className="faq"
            style={{
                zIndex: "1",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                padding: "2rem",
                textAlign: "left",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
        >
          <div>
            <h2>Frequently Asked Questions</h2>
            <p>Here are some frequently asked questions about SmartAI News.</p>
          </div>
          
          <div>
            {faqData.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    style={{
                        textAlign: "left",
                        padding: "0.5rem",
                        fontSize: "1.2rem",
                        fontWeight: "500",
                      }}
                  >{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    style={{
                      textAlign: "left",
                      paddingLeft: "1.2rem",
                      paddingRight: "1.2rem",
                      fontSize: "1.0rem",
                      fontStyle: "italic",
                      fontWeight: "400",
                    }}
                  >{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>

          <div
            style={{
              marginTop: "2rem",
            }}
          >
            <h2>Still have questions?</h2>
            <p>
              If you still have questions, please send us an email at{" "}
              <a href="mailto:info@smartai.systems">info@smartai.systems</a> or visit us at{" "}
              <a href="https://smartai.systems/contact-us">SmartAI Inquiry Page</a>
            </p>
          </div>
        </div>
      </Box>
      <Subscribe to="daily" />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default FAQ;
