import React from 'react';
import { subscribe } from '../../services/apiHero';

const SubmitHandler = (e) => {
  e.preventDefault();  

  const email = e.target[0].value;
  //console.log('email: ', email);

  subscribe(email);

  alert('Thank you for subscribing to our newsletter!');
};

const Subscribe = (props) => {
  return (
    <section className="wpo-subscribe-section section-padding">
      <div className="container">
        <div className="wpo-subscribe-wrap">
          <div className="subscribe-text">
            <h3>Never miss a thing!</h3>
            <p>
              Get daily headlines and updates sent to your email inbox.
            </p>
          </div>
          <div className="subscribe-form">
            <form onSubmit={SubmitHandler}>
              <div className="input-field">
                <input type="email" placeholder="Email Address" required />
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
