import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Hero from '../../components/hero/hero';
import Scrollbar from '../../components/scrollbar/scrollbar';
import TrendingNews from '../../components/TrendingNews/TrendingNews';
import Footer from '../../components/footer/Footer';
import Subscribe from '../../components/Subscribe/Subscribe';
import { usePosts } from '../../hooks/usePosts';

const HomePage = () => {
 
  const { isLoading } = usePosts();

  if (isLoading)
    return (
      <Fragment>
        <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
        <Hero />
      </Fragment>
    );

  return (
    <Fragment>
      <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
      <Hero />
      <TrendingNews />
      <Subscribe />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
