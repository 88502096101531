import React from 'react';
import { Box, Text } from '@deposits/ui-kit-react';
import GridLayout from './GridLayout';
import { Menu } from '@mantine/core';

const BookmarkLayout = ({ firstColumnContent, gridItems }) => {
  return (
    <div>
      <div className="column__layout">
        <div className="first__column__section p-2">{firstColumnContent}</div>

        <div className="second__column__section p-2">
          <div className="news__feed__nav__section">
            <Box>
              <Text className="header__category__text"> Bookmarks</Text>
            </Box>
            <Box>
              <Text>
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Box className="nav__main__text__font">Sort By</Box>
                  </Menu.Target>

                  <Menu.Dropdown className="header__menu">
                    <Menu.Item className="header__menu__drop">Date</Menu.Item>
                    <Menu.Item className="header__menu__drop">Score</Menu.Item>
                    <Menu.Item className="header__menu__drop">
                      Alphabetical
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Text>
            </Box>
          </div>
          <GridLayout>{gridItems}</GridLayout>
          <div className="bookmarkButton">
            <button className="bookmarkButton__loadMore">Load more</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookmarkLayout;
