import React from "react";
import { Box, Text } from "@deposits/ui-kit-react";
import arrowBackIcon from "../Icons/arrowBackIcon.svg";
import { useMoveBack } from "../hooks/useMoveBack";

const NewsFeedLayout = ({ firstColumnContent, secondColumnContent }) => {
  const moveBack = useMoveBack();
  return (
    <div>
      <div className="post__details__column__layout">
        {/* First Column (30% width) */}
        <div className="first__column__section p-2">
          {/* Render content passed as props */}
          {firstColumnContent}
        </div>

        {/* Second Column (55% width) */}
        <div className="second__column__section p-2">
          <div className="nav__section" onClick={moveBack}>
            <Box is="img" src={arrowBackIcon} width="20px" />
            <Box>
              <Text className="nav__main__text__font"> Go back</Text>
            </Box>
          </div>
          {/* Second column content (Grid Layout) */}
          {secondColumnContent}
        </div>
      </div>
    </div>
  );
};

export default NewsFeedLayout;
