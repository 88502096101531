import { Box, Text } from '@deposits/ui-kit-react';
import profileIcon from '../../Icons/profileIcon.svg';
import { useCategory } from '../CategoriesWidget/useCategory';
import { useMoveBack } from '../../hooks/useMoveBack';
import arrowBackIcon from '../../Icons/arrowBackIcon.svg';
import { Link } from 'react-router-dom';

const ProfileWidget = () => {
  const { isLoading, categories } = useCategory();
  const moveBack = useMoveBack();
  if (isLoading) return null;

  const filteredCategories = categories.filter((category) => {
    return (
      category === 'Business' || category === 'Science' || category === 'World'
    );
  });
  return (
    <Box>
      <div className="nav__section" onClick={moveBack}>
        <Box is="img" src={arrowBackIcon} width="20px" />
        <Box>
          <Text className="nav__main__text__font">Back</Text>
        </Box>
      </div>
      <Box className="category__widget_layout">
        <Box className="profile__section">
          <Box>
            <Box is="img" src={profileIcon} width="40px" />
          </Box>
          <Box className="main_category_text">
            <Text className="header__category__text"> andiebee</Text>
          </Box>
        </Box>
        <Box>
          {filteredCategories.map((category, index) => (
            <Box key={index}>
              <Link to={`/${category.toLowerCase()}`}>
                <Text className="category__text">{category}</Text>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileWidget;
