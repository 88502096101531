import React, { Fragment } from 'react';
import BlogHeader from '../../components/FeedHeader/BlogHeader';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import PostDetailsLayout from '../../layouts/PostDetailsLayout';
import PostDetails from '../../components/PostDetails/PostDetails';
import InfoWidget from '../../components/InfoWidget/InfoWidget';
import { usePostDetails } from '../../components/PostDetails/usePostDetails';
import SpinnerMini from '../../components/Spinner/SpinnerMini';

const PostDetailsPage = () => {
  const { isLoading } = usePostDetails();

  if (isLoading)
    return (
      <Fragment>
        <BlogHeader />
        <SpinnerMini />
      </Fragment>
    );

  return (
    <Fragment>
      <BlogHeader />
      <PostDetailsLayout
        firstColumnContent={<InfoWidget />}
        secondColumnContent={<PostDetails />}
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PostDetailsPage;
