import { Link } from "react-router-dom";
import getImageUrl from "../NoImage/NoImage";

import { daysAgo } from "../../services/utilsStringFuncs";
import { Box, Button } from "@material-ui/core";
import {
  cleanSummary,
  getChannelString,
  truncateString,
  AIGeneratedSection
} from "../../services/utilsStringFuncs";


const handleCategoryClick = (category) => () => {
  window.location.href = `/${category.toLowerCase()}`;
};

const generateCategoryButton = (category) => {
  if (!category) return null;

  var category = category.toUpperCase();
  var categoryWithPrefix = "CATEGORY: " + category;

  return (
    <Button
    title={categoryWithPrefix}
      className="thumb"
      variant="contained"
      style={{
        backgroundColor: "var(--color-header)",
        borderRadius: "25px",
        fontSize: "1.0rem",
        position: "absolute",
        left: "5%",
        top: "5%",
      }}
      onClick={handleCategoryClick(category)}
    >
      {category}
    </Button>
  );
};

function HeroItem({ post }) {
  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: "var(--color-background)",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
      }}
    
    >

    <Box className="img-holder"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: "var(--color-background)",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
      }}      
    >
      <img
        src={getImageUrl(post.imageUrl)}
        alt="news"
        className="img img-responsive"
        style={{
          boxShadow: "10px 10px 10px 10px green",
          border: "4px solid var(--color-border)",
        }}
        title={cleanSummary(post)}
        onClick={() => window.open(post.url, "_blank")}
      />
       
      <div className="wpo-blog-content">
        {generateCategoryButton(post.category)}
        
        <div
          style={{
            height: "220px",
            fontSize: "1.4rem",
            padding: "10px 10px 10px 10px",

            color: "#ffffff",
            /* Box Shadow */
          }}
        >
          <div
            style={{
              paddingTop: "1rem",
              fontSize: "1.4rem",
              fontWeight: "bold",
              color: "var(--color-text)",
            }}
          >
            <Link
              title={post.title}
              style={{ color: "ButtonFace" }}
              to={post.url}
            >
              {truncateString(post.title, 100)}
            </Link>
          </div>

          <div
            style={{
              fontSize: "1.0rem",
              fontStyle: "italic",
              color: "var(--color-text)",
            }}
          >
            {post.publisher.publisherName} | {post.channel.channelId}
            {//getChannelString(post.channel.channelId)
            }
          </div>
          <div
            style={{
              fontSize: "1.0rem",
              fontStyle: "italic",
              color: "var(--color-text)",
            }}
          >
            {daysAgo(post.publisher.publishDateString)}
          </div>          
        </div>       
      </div>
    </Box>
    {AIGeneratedSection(post)}

    </Box>
    
  );
}

export default HeroItem;
