import { useQuery } from "@tanstack/react-query";
import { getSinglePost } from "../../services/apiHero";
import { useParams } from "react-router-dom";

export function usePostDetails() {
  const { postId } = useParams();

  const {
    isLoading,
    data: post,
    error,
  } = useQuery({
    queryKey: ["post", postId],
    queryFn: () => getSinglePost(postId),
    retry: false,
  });

  return { isLoading, post, error };
}
