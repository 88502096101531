import { useQuery } from '@tanstack/react-query';
import { getGovernmentPosts } from '../../services/apiCategories';

export function useGovernment() {
  const {
    isLoading,
    data: posts,
    error,
  } = useQuery({
    queryKey: ['GovernmentPosts'],
    queryFn: getGovernmentPosts,
  });

  return { isLoading, posts, error };
}
