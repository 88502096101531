import { useDynamicPosts } from '../../hooks/useDynamicPosts';
import SpinnerMini from '../Spinner/SpinnerMini';
import '../../sass/components/_hero.scss';
// import usePostQueryStore from '../../store';
import arrowBackIcon from '../../Icons/arrowBackIcon.svg';
// import { useMoveBack } from '../../hooks/useMoveBack';
//import SearchItem from './SearchItem';
import HeroItem from '../hero/HeroItem';
import { useNavigate } from 'react-router-dom';

const Search = () => {
  const { isLoading, data, error } = useDynamicPosts();
  //   const setSearchText = usePostQueryStore((s) => s.setSearchText);
  //   const moveBack = useMoveBack();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  if (isLoading) return <SpinnerMini />;

  if (error)
    return (
      <div className="post-nf post-nf--search">
        <div className="post-nf__back">
          <img
            onClick={handleBackClick}
            className="post-nf__arrow"
            src={arrowBackIcon}
            alt="back arrow"
          />
          <p
            onClick={handleBackClick}
            className="post-nf__backText post--nf__backText--search"
          >
            Go back
          </p>
        </div>

        <p className="post-nf__text post-nf__text--search">
          Oops! Post not found
        </p>
      </div>
    );

  return (
    <div
      className="wpo-blog-hero-area wpo-blog-hero-area--search"
      style={{ paddingTop: '0', minHeight: '80vh' }}
    >
      <div className="container">
        <div className="sortable-gallery">
          <div className="gallery-filters"></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="wpo-blog-grids gallery-container clearfix">
                <div className="post-nf">
                  <div className="post-nf__back post-nf__back--search">
                    <img
                      onClick={handleBackClick}
                      className="post-nf__arrow post-nf__arrow--search"
                      src={arrowBackIcon}
                      alt="back arrow"
                    />
                    <p
                      onClick={handleBackClick}
                      className="post-nf__backText post-nf__backText--search"
                    >
                      Go back
                    </p>
                  </div>
                </div>

                {data.map((post, index) => (
                  <div className="grid" key={post.id}>
                    <HeroItem post={post} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
