import { useQuery } from "@tanstack/react-query";
import { getCategory } from "../../services/apiCategory";

export function useCategory() {
  const {
    isLoading,
    data: categories,
    error,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategory,
  });

  return { isLoading, categories, error };
}
