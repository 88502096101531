import { useQuery } from '@tanstack/react-query';
import { getHealthPosts } from '../../services/apiCategories';

export function useHealth() {
  const {
    isLoading,
    data: posts,
    error,
  } = useQuery({
    queryKey: ['healthPosts'],
    queryFn: getHealthPosts,
  });

  return { isLoading, posts, error };
}
