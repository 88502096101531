import { Badge, Box, Text } from '@deposits/ui-kit-react';
import React, { useState } from 'react';
import CommentCard from '../CommentCard/CommentCard';
// import { Link } from "react-router-dom";
import thumbsUpIcon from '../../Icons/thumbsUpIcon.svg';
import thumbsDownIcon from '../../Icons/thumbsDownIcon.svg';
import helpIcon from '../../Icons/helpIcon.svg';
import commentIcon from '../../Icons/commentIcon.svg';
import shareIcon from '../../Icons/shareIcon.svg';
import bookmarkIcon from '../../Icons/bookmarkIcon.svg';
import { usePostDetails } from './usePostDetails';
import SpinnerMini from '../Spinner/SpinnerMini';
import { Menu } from '@mantine/core';
import copy from 'copy-to-clipboard';
import { daysAgo } from '../../services/utilsStringFuncs';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

function PostDetails() {
  const [isToggled, setIsToggled] = useState(false);
  const [thumbsUpCount, setThumbsUpCount] = useState(73);
  const [thumbsDownCount, setThumbsDownCount] = useState(27);

  const shareUrl = 'https://smartai.news';

  const handleThumbsUpClick = () => {
    setThumbsUpCount(thumbsUpCount + 1);
    // updatePostInteraction(postId, 'thumbsUp');
  };

  const handleThumbsDownClick = () => {
    setThumbsDownCount(thumbsDownCount + 1);
    // updatePostInteraction(postId, 'thumbsDown');
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  const { post, isLoading } = usePostDetails();

  const copyText = `${post.title} via ${shareUrl}`;

  const handleCopy = () => {
    copy(copyText);
  };

  if (isLoading) return <SpinnerMini />;

  return (
    <Box className="post__details__layout">
      <Box>
        <Box
          is="img"
          className="post__details__image"
          src={post.imageUrl}
          width="100%"
          alt=""
        />
      </Box>
      <Box className="post__content__section">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          gap="0.5em"
          paddingTop="1em"
        >
          <Badge smartColor="#A51A1A" size="huge">
            <Text className="badge__text">{post.category}</Text>
          </Badge>
          <Badge smartColor="#357C78" size="huge">
            <Text className="badge__text">{post.topics[0]}</Text>
          </Badge>
          <Badge smartColor="#AE4910" size="huge">
            <Text className="badge__text">{post.topics[1]}</Text>
          </Badge>
        </Box>
        <Box>
          <Text className="header__text"> {post.title}</Text>
          <Box className="news__source">
            <Text className="Post__headline__info__text">{daysAgo(post.publisher.publishDateString)}</Text>           
          </Box>
          <Text className="post__content__details">{post.description}</Text>
        </Box>
        <Box className="post__reaction__section">
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="1em"
              alignItems="center"
            >
              <Box className="post__thumbs_section">
                <Box
                  className="post__thumbs__box"
                  onClick={handleThumbsUpClick}
                  is="img"
                  src={thumbsUpIcon}
                  width="40%"
                />
                <Text className="post__reaction__sub__text">
                  {thumbsUpCount}
                </Text>
              </Box>
              <Box className="post__thumbs_section">
                <Box
                  className="post__thumbs__box"
                  onClick={handleThumbsDownClick}
                  is="img"
                  src={thumbsDownIcon}
                  width="40%"
                />
                <Text className="post__reaction__sub__text">
                  {thumbsDownCount}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box display="flex" gap="4px">
            <Box is="img" src={helpIcon} width="15px" />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap="6px"
            >
              <Text className="post__reaction__main__text text__alignment">
                Veracity score:
              </Text>
              <Text className="post__reaction__sub__text text__alignment">
                {post.metadata.veracityScore.toFixed(2)}
              </Text>
            </Box>
          </Box>
          <Box display="flex" gap="4px">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap="6px"
            >
              <Text className="post__reaction__main__text text__alignment">
                Lean:
              </Text>
              <Text className="post__reaction__sub__text text__alignment">
                Left, right, center
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={`info__card__footer ${isToggled ? 'toggled' : ''}`}>
        <Box display="flex" flexDirection="row" gap="1em">
          <Box className="reaction__score" onClick={handleToggle}>
            <Box is="img" src={commentIcon} width="30%" />
            <Text className="post__reaction__sub__text text__alignment">
              {isToggled ? 'Comments' : post.metadata.comments}
            </Text>
          </Box>
          <Box className="reaction__score">
            <Text className="post__reaction__sub__text text__alignment">
              <Menu shadow="md" width={350} position="top">
                <Menu.Target>
                  <Box className="reaction__score" onClick={handleCopy}>
                    <Box is="img" src={shareIcon} width="30%" />
                    <Box className="post__reaction__sub__text text__alignment">
                      Share
                    </Box>
                  </Box>
                </Menu.Target>

                <Menu.Dropdown className="header__menu header__menu--social">
                  <div className="header__menu__div">
                    <Menu.Item className="header__menu__drop">
                      <FacebookShareButton url={shareUrl} quote={post.title}>
                        <FacebookIcon size={40} round={true} />
                      </FacebookShareButton>
                    </Menu.Item>
                    <Menu.Item className="header__menu__drop">
                      <TwitterShareButton url={shareUrl} title={post.title}>
                        <TwitterIcon size={40} round={true} />
                      </TwitterShareButton>
                    </Menu.Item>
                    <Menu.Item className="header__menu__drop">
                      <LinkedinShareButton
                        url={shareUrl}
                        title={post.title}
                        summary={post.description}
                      >
                        <LinkedinIcon size={40} round={true} />
                      </LinkedinShareButton>
                    </Menu.Item>
                    <Menu.Item className="header__menu__drop">
                      <WhatsappShareButton url={shareUrl} title={post.title}>
                        <WhatsappIcon size={40} round={true} />
                      </WhatsappShareButton>
                    </Menu.Item>
                  </div>
                </Menu.Dropdown>
              </Menu>
            </Text>
          </Box>
        </Box>
        <Box className="reaction__score">
          {/* <Link to={`/bookmark-details-page`}> */}
          <Box is="img" src={bookmarkIcon} width="30%" />
          <Text className="post__reaction__sub__text text__alignment">
            Bookmark
          </Text>
          {/* </Link> */}
        </Box>
      </Box>
      <Box className="comment__card">{isToggled && <CommentCard />}</Box>
    </Box>
  );
}

export default PostDetails;
