import { Link } from 'react-router-dom';
import { Badge, Box, Text } from '@deposits/ui-kit-react';
import thumbsUpIcon from '../../Icons/thumbsUpIcon.svg';
import thumbsDownIcon from '../../Icons/thumbsDownIcon.svg';
import helpIcon from '../../Icons/helpIcon.svg';
import commentIcon from '../../Icons/commentIcon.svg';
import shareIcon from '../../Icons/shareIcon.svg';
import bookmarkIcon from '../../Icons/bookmarkIcon.svg';
import getImageUrl from '../NoImage/NoImage';
import SpinnerMini from '../Spinner/SpinnerMini';
import { usePosts } from '../../hooks/usePosts';

const BookmarkHeadlines = () => {
  const { isLoading, posts, error } = usePosts();

  if (isLoading) return <SpinnerMini />;

  if (error) return <div>An error occured</div>;

  return (
    <>
      {posts.slice(0, 4).map((post, index) => (
        <Box className="headline__layout" key={index}>
          <Box>
            <img
              className="headline__image"
              src={getImageUrl(post.imageUrl)}
              alt="Post"
              width="100%"
            />
          </Box>
          <Box className="content__section">
            <Box
              display="flex"
              flex-direction="row"
              justify-content="flex-start"
              gap="1em"
              padding-top="1em"
            >
              <Box>
                <Badge smartColor="#A51A1A" size="medium">
                  <Text className="badge__text">{post.category}</Text>
                </Badge>
              </Box>
              <Box>
                <Badge smartColor="#357C78" size="medium">
                  <Text className="badge__text">{post.topics[0]}</Text>
                </Badge>
              </Box>
              <Box>
                <Badge smartColor="#AE4910" size="medium" text="Cartegory">
                  <Text className="badge__text">{post.topics[1]}</Text>
                </Badge>
              </Box>
            </Box>
            <Box></Box>
            <Box>
              <Link to={post.url}>
                <Text className="header__blog__text">{post.title}</Text>
              </Link>
              <Box className="news__source">
                <Text className="headline__info__text">{post.publishDateString}</Text>    
              </Box>
              <Text className="Headline__details">{post.description}</Text>
            </Box>
            <Box className="reaction__section">
              <Box display="flex" gap="4px">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flexStart"
                  gap="6px"
                >
                  <Box is="img" src={thumbsUpIcon} width="15%" />
                  <Text className="headline__reaction__sub__text">
                    {post.metadata.likes}
                  </Text>

                  <Box is="img" src={thumbsDownIcon} width="15%" />
                  <Text className="headline__reaction__sub__text">
                    {post.metadata.dislikes}
                  </Text>
                </Box>
              </Box>
              <Box display="flex" gap="4px">
                <Box is="img" src={helpIcon} width="15px" />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  gap="6px"
                >
                  <Text className="headline__reaction__main__text text__alignment">
                    Veracity Score:
                  </Text>
                  <Text className="headline__reaction__sub__text text__alignment">
                    {post.metadata.veracityScore.toFixed(2)}
                  </Text>
                </Box>
              </Box>
              <Box display="flex" gap="4px">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  gap="6px"
                >
                  <Text className="headline__reaction__main__text text__alignment">
                    Lean:
                  </Text>
                  <Text className="headline__reaction__sub__text text__alignment">
                    Left
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="info__card__footer">
            <Box display="flex" flexDirection="row" gap="2em">
              <Box className="reaction__score">
                <Box is="img" src={commentIcon} width="50%" />
                <Text className="headline__reaction__sub__text">
                  {post.metadata.comments}
                </Text>
              </Box>
              <Box className="reaction__score">
                <Box is="img" src={shareIcon} width="50%" />
                <Text className="headline__reaction__sub__text">
                  {post.metadata.shares}
                </Text>
              </Box>
            </Box>
            <Box className="reaction__score">
              <Link to={`bookmark-details-page/${post.id}`}>
                <Box is="img" src={bookmarkIcon} width="80%" />
                <Text className="post__reaction__sub__text text__alignment"></Text>
              </Link>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BookmarkHeadlines;
