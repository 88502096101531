import { useQuery } from '@tanstack/react-query';
import { getEnvironmentPosts } from '../../services/apiCategories';

export function useEnvironment() {
  const {
    isLoading,
    data: posts,
    error,
  } = useQuery({
    queryKey: ['environmentPosts'],
    queryFn: getEnvironmentPosts,
  });

  return { isLoading, posts, error };
}
