import { useDynamicPosts } from '../../hooks/useDynamicPosts';
import BlogHeader from '../FeedHeader/BlogHeader';
import Footer from '../footer/Footer';
import Search from './Search';

function SearchPage() {
  const { isLoading } = useDynamicPosts();

  if (isLoading)
    return (
      <>
        <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
        <Search />
      </>
    );

  return (
    <>
      <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
      <Search />
      <Footer />
    </>
  );
}

export default SearchPage;
