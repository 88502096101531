import { useState } from "react";
import { Box, Text } from "@deposits/ui-kit-react";
import profileIcon from "../../Icons/profileIcon.svg";
import optionIcon from "../../Icons/optionIcon.svg";
import likeIcon from "../../Icons/likeIcon.svg";
import commentIcon from "../../Icons/commentIcon.svg";
import RepliesComponents from "../RepliesComponents/RepliesComponents";
import Modal from "../Modal/Modal";

function ToggleComponent() {
  const [isToggled, setIsToggled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggle = (event) => {
    // Check if the clicked element has the "optionIcon" class
    const isOptionIconClicked = event.target.classList.contains('optionIcon');

    if (!isOptionIconClicked) {
      setIsToggled((prevState) => !prevState);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
      <Box>
        <Box className="comment_card__layout">
          <Box className="profile__photo_area">
            <Box>
              <Box is="img" src={profileIcon} width="40px" />
            </Box>
          </Box>
          <Box className="comment__details__layout">
            <Box className="username__section">
              <Box className="username__area">
                <Text className="username__text">Username</Text>
                <Text className="post__duration__text text__alignment">5 days ago</Text>
              </Box>
              <Box >
                <Box is="img" src={optionIcon} onClick={openModal} />
              </Box>
            </Box>
            <Box>
              <Text className="news__content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. In eu
                mi bibendum neque egestas congue quisque egestas. Pellentesque eu
                tincidunt tortor aliquam nulla facilisi cras fermentum odio.
              </Text>
              <Box display="flex" gap="1em">
                <Box className="actions__section">
                  <Box is="img" src={likeIcon} width="20px" />
                  <Text className="action_text text__alignment">25</Text>
                </Box>
                <Box className="actions__section">
                  <Box is="img" src={commentIcon} width="20px" />
                  <Text className="action_text text__alignment">Reply</Text>
                </Box>
                <Box className="actions__section">
                  <Text className="action_text" onClick={(event) => handleToggle(event)}>
                    {isToggled ? "Close Replies" : "Load Replies"}
                  </Text>
                </Box>
              </Box>
              <Box>{isToggled && <RepliesComponents />}</Box>
            </Box>
          </Box>
        </Box>
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            text={['Report', 'Block']}
        />
      </Box>
  );
}

const CommentCard = () => {
  return <ToggleComponent />;
};

export default CommentCard;
