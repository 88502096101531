import React from "react";
import { Box, Text } from "@deposits/ui-kit-react";
import LogoComponent from "../FeedHeader/LogoComponent";
// import profileIcon from "../../Icons/profileIcon.svg";
import greenLinkedinIcon from "../../Icons/greenLinkedinIcon.svg";
import purpleLinkedinIcon from "../../Icons/purpleLinkedinIcon.svg";
import greenInstagramIcon from "../../Icons/greenInstagramIcon.svg";
import purpleInstagramIcon from "../../Icons/purpleInstagramIcon.svg";
import greenTwitterIcon from "../../Icons/greenTwitterIcon.svg";
import purpleTwitterIcon from "../../Icons/purpleTwitterIcon.svg";
import { Link } from "react-router-dom";
import { useColorMode } from "../../context/ColorModeContext";
import greenFooterIcon from "../../Icons/greenFooterIcon.svg";
import purpleFooterIcon from "../../Icons/purpleFooterIcon.svg";
import platform from "../../images/logo2_tiny.png";

const SmartAIPlatformLogo = (
  <Box className="text-platform" style={{ paddingRight: "1.5rem" }}>
    <img
      src={platform}
      alt="SmartAI Generative Platform (SSGP) - smartai.systems"
      style={{
        border: "2px",
        borderRadius: "10%",
        width: "100px",
        height: "100px",
        display: "flex",
        margin: "0 auto",
      }}
    />
    <div
      style={{
        fontSize: "0.8rem",
        fontStyle: "italic",
        color: "var(--color-text)",
      }}
    >
      powered by SSGP, the
      <p />
      SmartAI Generative Platform
    </div>
  </Box>
);
/*
.logo-si {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  border-radius: 10%;
}

.logo-platform {
    border: 2px;
    border-radius: 10%;

    
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }
*/

const Footer = (props) => {
  const { colorMode, colorModes } = useColorMode();
  return (
    <footer className="wpo-site-footer">
      <Box is="header" id="navbar">
        <Box className="footer__section">
          <Box is={Link} className="logo footer__section__logo" to="/">
            <LogoComponent
              src={`${
                colorMode === colorModes.green
                  ? greenFooterIcon
                  : purpleFooterIcon
              }`}
              width="75%"
            />
          </Box>
          <Box
            display="flex"
            flex-direction="row"
            gap="1em"
            align-self="flex-end"
          >
            <Text className="footer__text__links">
              <Link to={"https://smartai.systems"}>About Us</Link>
            </Text>
            <Text className="footer__text__links">
              <Link to="/faq">FAQ</Link>
            </Text>
            <Text
              className="footer__text__font"
              style={{
                fontSize: "0.9rem",
                fontStyle: "italic",
                color: "var(--color-text)",
                marginTop: "0.2rem",
                paddingLeft: "1.5rem",
              }}
            >
              &copy; SmartAI Technologies 2023/2024
            </Text>
          </Box>

          <Box className="footer__socials">
            <Box is="a" href="#">
              <Box
                is="img"
                src={`${
                  colorMode === colorModes.green
                    ? greenTwitterIcon
                    : purpleTwitterIcon
                }`}
                width="15px"
              />
            </Box>
            <Box is="a" href="https://www.instagram.com/smartai.systems/">
              <Box
                is="img"
                src={`${
                  colorMode === colorModes.green
                    ? greenInstagramIcon
                    : purpleInstagramIcon
                }`}
                width="15px"
              />
            </Box>
            <Box is="a" href="https://www.linkedin.com/company/smartai-systems">
              <Box
                is="img"
                src={`${
                  colorMode === colorModes.green
                    ? greenLinkedinIcon
                    : purpleLinkedinIcon
                }`}
                width="15px"
              />
            </Box>
          </Box>
          {SmartAIPlatformLogo}
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
