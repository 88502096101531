import { Box, Text } from '@deposits/ui-kit-react';
import helpIcon from '../../Icons/helpIcon.svg';
import thumbsUpIcon from '../../Icons/thumbsUpIcon.svg';
import thumbsDownIcon from '../../Icons/thumbsDownIcon.svg';
import { usePostDetails } from '../PostDetails/usePostDetails';
import { Table } from '@mantine/core';

const InfoWidget = () => {
  const { post, isLoading } = usePostDetails();

  if (isLoading) return null;

  return (
    <Box className="info__widget__box">
      <Box className="info__widget_layout">
        <Table>
          <tbody>
            <tr>
              <td>
                <Box className="widget__info">
                  <Text className="header__text__font">Info</Text>
                  <Box is="img" src={helpIcon} width="15px" />
                </Box>
              </td>
            </tr>
            <tr>
              <td>
                <Text className="info__main__text__font text__alignment">
                  Veracity Score
                </Text>
              </td>
              <td>
                <Text className="info__sub__text__font text__alignment">
                  {post.metadata.veracityScore.toFixed(2)}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text className="info__main__text__font text__alignment">
                  Lean
                </Text>
              </td>
              <td>
                <Text className="info__sub__text__font text__alignment">
                  Left
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Box className="thumbs_section">
                  <Box is="img" src={thumbsUpIcon} width="19%" />
                  <Text className="info__sub__text__font text__alignment">
                    {post.metadata.likes}
                  </Text>
                </Box>
              </td>
              <td>
                <Box className="thumbs_section">
                  <Box is="img" src={thumbsDownIcon} width="30%" />
                  <Text className="info__sub__text__font text__alignment">
                    {post.metadata.dislikes}
                  </Text>
                </Box>
              </td>
            </tr>
            {/* <tr>
              <td>
                <Box className="badge__section">
                  <Box paddingTop="1em">
                    <Badge smartColor="#A51A1A" size="small">
                      <Text className="badge__text">{post.category}</Text>
                    </Badge>
                  </Box>
                  <Box paddingTop="1em">
                    <Badge smartColor="#357C78" size="small">
                      <Text className="badge__text">{post.category}</Text>
                    </Badge>
                  </Box>
                  <Box paddingTop="1em">
                    <Badge smartColor="#AE4910" size="small" text="Cartegory">
                      <Text className="badge__text">{post.category}</Text>
                    </Badge>
                  </Box>
                </Box>
              </td>
            </tr> */}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default InfoWidget;
