import { useQuery } from '@tanstack/react-query';
import ms from 'ms';
// import { getPosts } from '../../services/apiHero';
import usePostQueryStore from '../store';
import APIClient from '../services/apiClient';

// const apiClientPosts = new APIClient('/posts');
const apiClientSearch = new APIClient('/posts/search');

export function useDynamicPosts() {
  const postQuery = usePostQueryStore((s) => s.postQuery);

  // const fetchPosts = async (searchText) => {
  //   if (searchText) {
  //     return apiClientSearch.getAll({
  //       params: {
  //         keyword: searchText,
  //       },
  //     });
  //   } else {
  //     return apiClientPosts.getAll({
  //       params: {
  //         filter: 'sports',
  //       },
  //     });
  //   }
  // };

  const fetchPosts = async (searchText) => {
    return apiClientSearch.getAll({
      params: {
        keyword: searchText,
      },
    });
  };

  return useQuery({
    queryKey: ['dynamicPosts', postQuery],
    queryFn: () => fetchPosts(postQuery.searchText),
    retry: false,
    staleTime: ms('24h'),
  });
}
