import { useQuery } from '@tanstack/react-query';
import { getSciencePosts } from '../../services/apiCategories';

export function useScience() {
  const {
    isLoading,
    data: posts,
    error,
  } = useQuery({
    queryKey: ['sciencePosts'],
    queryFn: getSciencePosts,
  });

  return { isLoading, posts, error };
}
