import { useQuery } from '@tanstack/react-query';
import { getTechnologyPosts } from '../../services/apiCategories';

export function useTechnology() {
  const {
    isLoading,
    data: posts,
    error,
  } = useQuery({
    queryKey: ['technologyPosts'],
    queryFn: getTechnologyPosts,
  });

  return { isLoading, posts, error };
}
