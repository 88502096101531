import React, { Fragment, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';
import './style.css';
import '../../sass/components/_mobileMenu.scss';

const menus = [
  /*
  {
    id: 5,
    title: 'Profile',
    link: '/',
    submenu: [
      {
        id: 51,
        title: 'My profile',
        link: '/profile',
      },
      {
        id: 52,
        title: 'Bookmarks',
        link: '/bookmarks',
      },
      {
        id: 53,
        title: 'Settings',
        link: '/settings',
      },
    ],
  },*/
  {
    id: 1,
    title: 'Home',
    link: '/',
  },
  {
    id: 3,
    title: 'World',
    link: '/world',
  },
  {
    id: 4,
    title: 'Government',
    link: '/government',
  },
  {
    id: 5,
    title: 'Health',
    link: '/health',
  },
  {
    id: 7,
    title: 'Science',
    link: '/science',
  },
  {
    id: 9,
    title: 'Environment',
    link: '/environment',
  },
  {
    id: 13,
    title: 'Technology',
    link: '/technology',
  },
  {
    id: 15,
    title: 'Business',
    link: '/business',
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  //   const navigate = useNavigate();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? 'show' : ''}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? 'active' : null}
                key={mn}
              >
                {item.submenu ? (
                  <Fragment>
                    <p
                      className="subMenu__p"
                      onClick={() =>
                        setOpenId(item.id === openId ? 0 : item.id)
                      }
                    >
                      {item.title}
                      <i
                        className={
                          item.id === openId
                            ? 'fa fa-angle-up'
                            : 'fa fa-angle-down'
                        }
                      ></i>
                    </p>
                    <Collapse
                      in={item.id === openId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <Link
                                  onClick={ClickHandler}
                                  className="active"
                                  to={submenu.link}
                                >
                                  {submenu.title}
                                </Link>
                              </ListItem>
                            );
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <Link className="active" to={item.link}>
                    {item.title}
                  </Link>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
