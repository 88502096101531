import React, { useState, useEffect } from 'react';
import { Box, styled } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { truncateString } from '../../services/utilsStringFuncs';


  const TickerReact = (posts, maxLength) => {
    const [news, setNews] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    var length = Object.values(maxLength)[0];
    if (length === undefined || length === null ) length = 150;
    
  useEffect(() => {
    
    // Fetch news data from an API  or any other data source
    const fetchNews = async (posts, maxLength) => {
      try {

        var length = Object.values(maxLength)[0];
        if (length === undefined || length === null ) length = 150;
       
         // posts are [object object], so convert to array
        var postsRefined = Object.values(posts)[0];

        if (postsRefined === undefined) {
             return;
        }
       
       // declare news array
        var stuff = [];
        //console.log("TICKER REACT - post length - " + length);

        // convert posts to news
        for(var i = 0; i < postsRefined.length; i++) {
            var post = postsRefined[i];

            var newsItem = {
                title: truncateString(post.title, length),
                url: post.url,
                image_url: (post.imageUrl) ? post.imageUrl : '/static/media/greenLogoIcon.6c8ce2ee34bfe0f52c7610c91a58521a.svg'
            };
            
            // add news item to array
            stuff.push(newsItem);
        }

        //if (stuff.length === 0) stuff = mockData;
        setNews(stuff);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews(posts, maxLength);
  }, [posts, maxLength]);

  useEffect(() => {
    // Automatically update the current news index every 3 seconds
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [news]);

  // create safe image url
  function createSafeImageUrl(imageUrl) {
    if (imageUrl) {
        return imageUrl;
    } else {
        return '/static/media/greenLogoIcon.6c8ce2ee34bfe0f52c7610c91a58521a.svg';
    
    }   
}

const marqueeItem = (newsItem, titleLength) => {
  const isMobile = window.innerWidth <= 500;

    if (!newsItem) return null;
    return (
        <Box style={{ 
          display: 'flex', 
          alignItems: 'center',
          paddingRight: '15px',
          marginRight: '10px',
          width: '100%',
         }}>
            <img alt={newsItem.title}
                src={createSafeImageUrl(newsItem.image_url)} 
                height="60px"
                // create cool image border and shadow effect 
                style={{ 
                    borderRadius: '10%',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
                    border: '1px solid white',
                    padding: '2px',
                    margin: '1px',
                }}
            />
     
            <Link
                style={{ 
                  color: 'black', 
                  fontSize: '1.0rem', 
                  fontWeight: 'bold', 
                  padding: '15px',
                  marginRight: '10px',
                }}
                title={newsItem.title}
                to={newsItem.url}
                target="_blank"
                rel="noopener noreferrer"
            >{truncateString(newsItem.title,(isMobile ? 30 : titleLength))}
            </Link>
        </Box>
    );
}

// return a marquee box that flips through news items one at a time
return (
  // beautify box with drop shadows and rounded corners

  <Box
    style={{      
      alignContent: 'center',
      background: 'white',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
      borderRadius: '10px',
      padding: '10px',
      margin: '10px',
      width: '100%',
      height: '80px',
      overflow: 'hidden',
    }}
  >
    <Box
      style={{
        whiteSpace: 'nowrap',
        animation: 'marquee 15s linear infinite',
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      {marqueeItem(news[currentIndex], maxLength)}
  </Box>
</Box>


  );
}


export default TickerReact;
